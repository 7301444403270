// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ripple-circle {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: ripple 4s linear infinite;
}

@keyframes ripple {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  25% {
    opacity: 0.4;
    transform: scale(0.75);
  }
  50% {
    opacity: 0.7;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/background_animations/RippleBackground.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,0CAA0C;EAC1C,0CAA0C;EAC1C,uCAAuC;EACvC,oCAAoC;AACtC;;AAEA;EACE;IACE,UAAU;IACV,qBAAqB;EACvB;EACA;IACE,YAAY;IACZ,sBAAsB;EACxB;EACA;IACE,YAAY;IACZ,mBAAmB;EACrB;EACA;IACE,UAAU;IACV,qBAAqB;EACvB;AACF","sourcesContent":[".ripple-circle {\n  position: absolute;\n  border-radius: 50%;\n  background-color: rgba(255, 255, 255, 0.3);\n  border: 2px solid rgba(255, 255, 255, 0.5);\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  animation: ripple 4s linear infinite;\n}\n\n@keyframes ripple {\n  0% {\n    opacity: 0;\n    transform: scale(0.5);\n  }\n  25% {\n    opacity: 0.4;\n    transform: scale(0.75);\n  }\n  50% {\n    opacity: 0.7;\n    transform: scale(1);\n  }\n  100% {\n    opacity: 0;\n    transform: scale(1.5);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
