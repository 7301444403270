import React from "react";
import SimpleCarousel from "../components/ui/Carousel/Carousel";

const MainPage = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center ">
      <SimpleCarousel />
    </div>
  );
};
////SA IMI BAG PULA MERGE COAIEEEEEE SA TRANSFER DIN LOCALL in development, chiar merge
export default MainPage;
