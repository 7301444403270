const TermeniSiConditii = () => {
  return (
    <div
      style={{
        padding: "20px",
        lineHeight: "1.6",
        maxWidth: "800px",
        margin: "auto",
      }}
    >
      <h1>Termeni și Condiții pentru utilizarea platformei INTELECTA SRL</h1>
      <h2>1. Introducere</h2>
      <p>
        Bine ați venit pe platforma INTELECTA SRL, serviciu online destinat
        elevilor pentru îmbunătățirea procesului de învățare prin utilizarea
        inteligenței artificiale. Accesând și utilizând platforma noastră,
        sunteți de acord să respectați și să fiți legați de termenii și
        condițiile de mai jos.
      </p>
      <h2>2. Definiții</h2>
      <ul>
        <li>
          <strong>Platforma</strong>: Website-ul și serviciile asociate oferite
          de INTELECTA SRL.
        </li>
        <li>
          <strong>Utilizatori</strong>: Elevi și orice alte persoane fizice sau
          juridice care accesează și folosesc platforma.
        </li>
        <li>
          <strong>Conținut</strong>: Materiale didactice, cursuri, teste, jocuri
          educaționale, simulări, și orice alt material furnizat prin platformă.
        </li>
      </ul>
      <h2>3. Drepturi de Proprietate Intelectuală</h2>
      <p>
        Tot conținutul disponibil pe platforma INTELECTA SRL, inclusiv textele,
        grafica, logo-urile, iconurile, imaginile, clipurile audio și video,
        este proprietatea INTELECTA SRL și este protejat de legile drepturilor
        de autor și alte legi privind proprietatea intelectuală. Utilizatorii
        pot accesa și utiliza conținutul exclusiv în scopuri educaționale și
        non-comerciale.
      </p>
      <h2>4. Utilizarea Platformei</h2>
      <p>
        Utilizatorii sunt responsabili pentru păstrarea confidențialității
        contului și parolei și pentru restricționarea accesului la computerul
        lor, iar în cazul oricăror activități desfășurate sub numele de
        utilizator și parolă, utilizatorul este direct responsabil.
      </p>
      <h2>5. Limitarea Răspunderii</h2>
      <p>
        INTELECTA SRL nu garantează că platforma va fi neîntreruptă sau fără
        erori; de asemenea, nu oferim nicio garanție cu privire la rezultatele
        care pot fi obținute din utilizarea platformei sau la acuratețea sau
        fiabilitatea oricăror informații obținute prin intermediul platformei.
      </p>
      <h2>6. Politica de Confidențialitate</h2>
      <p>
        Datele personale furnizate de către utilizatori vor fi tratate cu
        respect și confidențialitate. Informațiile personale vor fi utilizate
        doar pentru a furniza și îmbunătăți serviciul, pentru comunicarea legată
        de contul utilizatorului și pentru transmiterea de informații
        educaționale.
      </p>
      <h2>7. Modificări ale Termenilor și Condițiilor</h2>
      <p>
        INTELECTA SRL își rezervă dreptul de a modifica acești termeni și
        condiții fără notificare prealabilă. Modificările vor fi efective
        imediat ce au fost postate pe platformă.
      </p>
      <h2>8. Contact</h2>
      <p>
        Pentru orice întrebări legate de acești termeni și condiții, vă rugăm să
        ne contactați prin e-mail la{" "}
        <a href="mailto:amargelatu@yahoo.com">amargelatu@yahoo.com</a> sau să ne
        sunați la numărul +0770890544.
      </p>
    </div>
  );
};
export default TermeniSiConditii;
